.about-me-intro {
	font-size: 18px;
}

.postLink {
	color: inherit;
	text-decoration: none;
}

.portfolio-tab-list {
	display: none;
}

.projectThumbnail {
	min-height: 229px;
}

.slide-show-project-modal {
	height: 80vh !important;
	overflow-y: scroll !important;
}

@media screen and (min-width: 576px) {
	.about-me-intro {
		font-size: 24px;
	}
}
